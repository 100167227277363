import React from "react";
import Layout from "../components/Layout";
import WhereToBuyUs from "../components/WhereToBuyUs/wheretobuyus";
import "../scss/wheretobuy.scss";
import Helmet from "react-helmet";

const WhereToBuy = (props) => {
  return (
    <Layout title="Where to Buy" description="Pick up a pack of our plant-based goodness, available both online and offline.">
      <>
        <Helmet>
          <link rel="alternate" href="https://www.firstpride.sg/wheretobuy" hreflang="en-sg" />
          <link rel="alternate" href="https://www.firstpridethailand.com/wheretobuy/" hreflang="en-th" />
        </Helmet>
        <div className="wheretobuy">
          <div className="wheretobuyBanner">
            <div className="wheretobuyBannerContent">
              <h1>WHERE TO BUY</h1>
              <h5>EASY TO ENJOY, EASY TO FIND.</h5>
            </div>
          </div>
          <div className="wheretobuyComponent">
            <div>
              <WhereToBuyUs heading={false} />
            </div>
          </div>
        </div>
        <div className="wheretobuyFooterBanner"></div>
      </>
    </Layout>
  );
};

export default WhereToBuy;
